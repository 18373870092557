import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

  // 客户信息统计
export function getCustomerStatistics(params) {
    return request({
        url: "bivale/custom/customer/statistics",
        method: 'post',
        data:params,
    });
}

// 客户设备信息统计
export function getCustomerEquipmentStatistics(params) {
    return request({
        url: "bivale/custom/customer/equipment/statistics",
        method: 'post',
        data:params,
    });
}

export function getCustomerTenantDetailData(params) {
    return request({
        url: "bivale/custom/customer/tenant",
        method: 'post',
        data:params,
    });
}