<!--新能源看板-->
<template>
  <div id="CustomerStatisticsPage" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
    <div class="header">
        <div class="header-left">
            <div class="header-name">
                {{ name }}
            </div>
        </div>
    </div>

    <div class="main">
        <div style="background: #ECECEC; padding: 10px;">
            <a-row :gutter="24">
                <a-col :span="4">
                    <a-card @click="showDrawer(1)" class="pointer">
                        <!-- :value-style="{ color: '#3f8600' }" -->
                        <!-- title="客户数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a3')"
                            :value="statistics.clientCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                           
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="4">
                    <a-card @click="showDrawer(2)"  class="pointer">
                        <!-- title="建筑数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a4')"
                            :value="statistics.siteGRCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="4">
                    <a-card @click="showDrawer(3)"  class="pointer">
                        <!--  -->
                        <!-- title="站点数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a5')"
                            :value="statistics.siteCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="4">
                    <a-card @click="showDrawer(4)"  class="pointer">
                        <!-- statistics.serviceCount -->
                        <!-- title="服务数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a6')"
                            :value="statistics.serviceCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="4">
                    <!-- @click="showDrawer(5)" -->
                    <a-card @click="showDrawer(5)"  class="pointer">
                        <!-- title="楼层/租户数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a7')"
                            :value="statistics.tenantCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>

                <a-col :span="4">
                    <!-- @click="showDrawer(6)" class="pointer" -->
                    <a-card >
                        <!-- title="设备数" -->
                        <a-statistic
                            :title="$t('customerStatistics.a8')"
                            :value="statistics.equipmentCount"
                            :precision="0"
                            suffix=""
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                    </a-card>
                </a-col>
            </a-row>
        </div>
        <div class="main2">
            <div class="main3" ref="main3" v-loading="equipmentCountTable.loading" >
                <div class="title">
                    <!-- 设备信息统计 -->
                    {{ $t('customerStatistics.a9') }}
                </div>
                <a-row :gutter="20" style="padding: 0.15rem;">
                    <a-col :span="24">
                        <div style="padding-bottom: 5px;">
                            <!-- 请选择统计分类！ -->
                            <a-select v-model="equipmentCountTable.type" style="width: 200px" @change="filterTypeChange" :placeholder="$t('customerStatistics.a41')">
                                <a-select-option v-for="(item,index) in equipmentCountTable.filterType" :key="index" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            <a-select v-model="equipmentCountTable.clientId" style="width: 200px;margin-left: 5px;" @change="filterClientChange" v-if="equipmentCountTable.kind>=1" :placeholder="$t('customerStatistics.a19')">
                                <a-select-option v-for="(item,index) in equipmentCountTable.clients" :key="index" :value="item.clientId">
                                    {{ item.clientName }}
                                </a-select-option>
                            </a-select>
                            <a-select v-model="equipmentCountTable.sitegrId" style="width: 200px;margin-left: 5px;" @change="filterSiteGrChange" v-if="equipmentCountTable.kind>=2" :placeholder="$t('customerStatistics.a18')">
                                <a-select-option v-for="(item,index) in equipmentCountTable.siteGrs" :key="index" :value="item.sitegrId">
                                    {{ item.siteGrName }}
                                </a-select-option>
                            </a-select>
                            <a-select v-model="equipmentCountTable.siteId" style="width: 200px;margin-left: 5px;" @change="filterSiteChange" v-if="equipmentCountTable.kind>=3" :placeholder="$t('customerStatistics.a23')">
                                <a-select-option v-for="(item,index) in equipmentCountTable.sites" :key="index" :value="item.siteId">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>

                            <a-select v-model="equipmentCountTable.icon" style="width: 200px;margin-left: 5px;" @change="filterIconChange" :placeholder="$t('customerStatistics.a11')">
                                <a-select-option v-for="(item,index) in equipmentCountTable.icons" :key="index" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            <a-button type="primary" icon="search"  @click="searchEquipmentCountInfo" style="margin-left: 20px" > 
                                <!-- 查询 -->
                                 {{ $t('energy.search') }}
                            </a-button>
                        </div>
                        <div style="display: flex;justify-content: space-between;padding:12px 0;border-bottom: 1px solid #e8e8e8;border-top: 1px solid #e8e8e8;">
                            <a-breadcrumb :style="{fontSize:'14px'}">
                                <!-- <a-breadcrumb-item><a-icon type="home" :style="{fontSize:'14px'}" /></a-breadcrumb-item> -->
                                <a-breadcrumb-item v-if="equipmentCountTable.steps[2] && equipmentCountTable.steps[2].name"><a @click="rollUpEquipmentCount(equipmentCountTable.steps[2])">{{equipmentCountTable.steps[2].name}}</a></a-breadcrumb-item>
                                <a-breadcrumb-item v-if="equipmentCountTable.steps[3] && equipmentCountTable.steps[3].name"><a @click="rollUpEquipmentCount(equipmentCountTable.steps[3])">{{equipmentCountTable.steps[3].name}}</a></a-breadcrumb-item>
                                <a-breadcrumb-item v-if="equipmentCountTable.steps[4] && equipmentCountTable.steps[4].name"><a @click="rollUpEquipmentCount(equipmentCountTable.steps[4])">{{equipmentCountTable.steps[4].name}}</a></a-breadcrumb-item>
                                <a-breadcrumb-item v-if="equipmentCountTable.steps[1] && equipmentCountTable.steps[1].name"><a @click="rollUpEquipmentCount(equipmentCountTable.steps[1])">{{equipmentCountTable.steps[1].name}}</a></a-breadcrumb-item>
                                
                            </a-breadcrumb>
                            <div style="margin-right:30px;font-size: 14px;"> 
                                <!-- 合计： -->
                                {{ $t('customerStatistics.a42') }}
                                ：
                                <span style="font-size: 24px;">{{ dataFormat(equipmentCountTable.count,0) }}</span>
                            </div>
                        </div>
                        <a-table :columns="equipmentCountTable.columns"  :pagination="equipmentCountTable.pagination" :data-source="equipmentCountTable.equipmentCountInfoList" 
                             :scroll="equipmentCountTable.size"  :rowKey="(record) => record.key">
                            <a slot="count" slot-scope="text,record" @click="drillingEquipmentCount(record)">{{ text }}</a>
                        </a-table>
                    </a-col>
                </a-row>
            </div>
            <div class="main3">
                <div class="title">
                    <!-- 服务信息统计 -->
                    {{ $t('customerStatistics.a13') }}
                </div>
                <a-row :gutter="20" style="padding: 0.15rem;">
                    <a-col :span="24">
                        <a-table :columns="serviceCountTable.columns" :pagination="serviceCountTable.pagination" :data-source="serviceCountTable.serviceCountInfoList"  
                            :scroll="serviceCountTable.size" :rowKey="(record) => record.siteServiceTypeID">
                            <a slot="clientCount"   slot-scope="text,record" @click="drillingServiceStatistics(record,1)">{{ text }}</a>
                            <a slot="siteGRCount"   slot-scope="text,record" @click="drillingServiceStatistics(record,2)">{{ text }}</a>
                            <a slot="siteCount"     slot-scope="text,record" @click="drillingServiceStatistics(record,3)">{{ text }}</a>
                        </a-table>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
    <div class="floor" >
        
    </div>
    <a-drawer
      :closable="false"
      :visible="visible"
      @close="onClose"
      :width="width"
      
    >
    <!-- -:body-style="{ paddingBottom: '80px' }" -->
        <template slot="title">
            <a-breadcrumb >
                <!-- <a-breadcrumb-item>Home</a-breadcrumb-item> -->
                <a-breadcrumb-item v-if="steps[2] && steps[2].record.name"><a @click="rollUpCustomerStatistics(steps[2])">{{steps[2].record.name}}</a></a-breadcrumb-item>
                <a-breadcrumb-item v-if="steps[3] && steps[3].record.name"><a @click="rollUpCustomerStatistics(steps[3])">{{steps[3].record.name}}</a></a-breadcrumb-item>
                <a-breadcrumb-item v-if="steps[4] && steps[4].record.name"><a @click="rollUpCustomerStatistics(steps[4])">{{steps[4].record.name}}</a></a-breadcrumb-item>
                <a-breadcrumb-item>{{ drawerTitle }}</a-breadcrumb-item>
            </a-breadcrumb>
        </template>
        <div  style="padding-bottom:10px;border-bottom: 1px solid #e8e8e8;"   v-if="kind<6">
            <a-select v-model="clientId" style="width: 200px" @change="clientFilterData"   v-if="kind>=2" :placeholder="$t('customerStatistics.a19')">
                <a-select-option v-for="(item,index) in initData.clients" :key="index" :value="item.clientId">
                    {{ item.clientName }}
                </a-select-option>
            </a-select>
            <a-select v-model="sitegrId" style="width: 200px;margin-left: 5px;" @change="grFilterData" v-if="kind>=3" :placeholder="$t('customerStatistics.a18')">
                <a-select-option v-for="(item,index) in initData.siteGrs" :key="index" :value="item.sitegrId">
                    {{ item.siteGrName }}
                </a-select-option>
            </a-select>
            <a-select v-model="siteId" style="width: 200px;margin-left: 5px;" @change="siteFilterData" v-if="kind>=4" :placeholder="$t('customerStatistics.a23')">
                <a-select-option v-for="(item,index) in initData.sites" :key="index" :value="item.siteId">
                    {{ item.name }}
                </a-select-option>
            </a-select>

            <a-select v-model="serviceId" style="width: 200px;margin-left: 5px;" @change="siteFilterData" v-if="kind===4" :placeholder="$t('customerStatistics.a24')">
                <a-select-option v-for="(item,index) in initData.services" :key="index" :value="item.siteServiceTypeId">
                    {{ item.siteServiceTypeName }}
                </a-select-option>
            </a-select>
            
        </div>
        
        
        <div :style="{display:(kind===1?'block':'none')}">
            <!-- :scroll="clientTable.size" -->
            <a-table :columns="clientTable.columns" :pagination="clientTable.pagination"  :data-source="clientTable.list"  :rowKey="(record) => record.clientId">
                <a slot="siteGRCount"   slot-scope="text,record" @click="drillingCustomerStatistics(record,2)">{{ text }}</a>
                <a slot="siteCount"     slot-scope="text,record" @click="drillingCustomerStatistics(record,3)">{{ text }}</a>
                <a slot="serviceCount"  slot-scope="text,record" @click="drillingCustomerStatistics(record,4)">{{ text }}</a>
                <a slot="tenantCount"   slot-scope="text,record" @click="drillingCustomerStatistics(record,5)">{{ text }}</a>
            </a-table>
        </div>
        <div :style="{display:(kind===2?'block':'none')}">
           
            <a-table :columns="siteGrTable.columns" :pagination="siteGrTable.pagination" :data-source="siteGrTable.list"  :rowKey="(record) => record.sitegrId">
                <a slot="siteCount"     slot-scope="text,record" @click="drillingCustomerStatistics(record,3)">{{ text }}</a>
                <a slot="serviceCount"  slot-scope="text,record" @click="drillingCustomerStatistics(record,4)">{{ text }}</a>
                <a slot="tenantCount"   slot-scope="text,record" @click="drillingCustomerStatistics(record,5)">{{ text }}</a>
            </a-table>
        </div>
        <div :style="{display:(kind===3?'block':'none')}">
            <a-table :columns="siteTable.columns" :pagination="siteTable.pagination" :data-source="siteTable.list"  :rowKey="(record) => record.siteId">
                
                <a slot="serviceCount"  slot-scope="text,record" @click="drillingCustomerStatistics(record,4)">{{ text }}</a>
                <a slot="tenantCount"   slot-scope="text,record" @click="drillingCustomerStatistics(record,5)">{{ text }}</a>
                
            </a-table>
        </div>
        <div :style="{display:(kind===4?'block':'none')}">
            <a-table :columns="serviceTable.columns" :pagination="serviceTable.pagination" :data-source="serviceTable.list"  :rowKey="(record) => record.key">
                
            </a-table>
        </div>
        <div :style="{display:(kind===5?'block':'none')}">
            <a-table :columns="tenantTable.columns" :pagination="tenantTable.pagination" :data-source="tenantTable.list" :scroll="tenantTable.size"  :rowKey="(record) => record.tenantId">
                
            </a-table>
        </div>
        <div :style="{display:(kind===6?'block':'none')}">
            <a-table :columns="serviceStatisticsTable.columns" :pagination="serviceStatisticsTable.pagination" :data-source="serviceStatisticsTable.list" :scroll="serviceStatisticsTable.size"  :rowKey="(record) => record.NO">
                
            </a-table>
        </div>
        <div style="display:flex;justify-content: space-between;padding:10px 0;border-top: 1px solid #e8e8e8;">
            <div v-if="kind===6">
                {{ drawerTitle }}
            </div>
            <div v-else>
                <a-breadcrumb >
                    <!-- <a-breadcrumb-item>Home</a-breadcrumb-item> -->
                    <a-breadcrumb-item v-if="steps[2] && steps[2].record.name"><a @click="rollUpCustomerStatistics(steps[2])">{{steps[2].record.name}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item v-if="steps[3] && steps[3].record.name"><a @click="rollUpCustomerStatistics(steps[3])">{{steps[3].record.name}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item v-if="steps[4] && steps[4].record.name"><a @click="rollUpCustomerStatistics(steps[4])">{{steps[4].record.name}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item>{{ drawerTitle }}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <!-- <div style="font-size: 16px;">合计：{{ dataFormat(count,0) }}</div> -->
            <div style="font-size: 14px;">
                <!-- 合计 -->
                {{ $t('customerStatistics.a42') }}
                ：
                <span style="font-size: 24px;">{{ dataFormat(count,0) }}</span>
            </div>
        </div>
        <div style="display:flex;justify-content: space-between;padding:10px 0;border-top: 1px solid #e8e8e8;">
            <div>
                <a-button type="primary" @click="rollUp" v-if="steps[kind]">
                    <!-- 返回 -->
                    {{ $t('Kanban.a32') }}
                </a-button>
            </div>
            <div>
                <a-button type="default" @click="onClose" >
                    <!-- 关闭 -->
                    {{ $t('video.viddata054') }}
                </a-button>
            </div>
        </div>
        <!-- <div v-else-if="kind===5">
            <a-table :columns="columns5" :pagination="pagination" :data-source="list"  :rowKey="(record) => record.sitegrId">
                
            </a-table>
        </div>
        <div v-else-if="kind===6">
            <a-table :columns="columns8" :pagination="pagination" :data-source="list"  :rowKey="(record) => record.type">
                
            </a-table>
        </div> -->
    </a-drawer>
  </div>
</template>

<script>

import {getCustomerStatistics,getCustomerEquipmentStatistics ,getCustomerTenantDetailData} from "../../api/customerStatistics";

export default {
    components:{
        
        // 'my-electricEnergy':electricEnergy,
        // 'my-noPhotovoltaic':noPhotovoltaic,
        // 'my-noWater':noWater
    },
    data(){
        let sel = this.$t('customerStatistics.a20');//请选择
        return{
            
            name:this.$t('customerStatistics.a1'),//'客户信息统计',
            loading:false,
            visible:false,
            initData:{
                client:{clientId:undefined,clientName:sel},
                siteGr:{sitegrId:undefined,siteGrName:sel},
                site:{siteId:undefined,name:sel},
                service:{siteServiceTypeId:undefined,siteServiceTypeName:sel},
                clients:[],
                siteGrs:[],
                sites:[],
                services:[{siteServiceTypeId:undefined,siteServiceTypeName:sel}],
            },
            columnMap:{
                title:{
                    dataIndex: 'title',
                    key: 'title',
                    title: this.$t('customerStatistics.a10'),
                },
                clientName:{
                    dataIndex: 'clientName',
                    key: 'clientName',
                    title: this.$t('customerStatistics.a15'),
                },
                siteGrName:{
                    dataIndex: 'siteGrName',
                    key: 'siteGrName',
                    title: this.$t('customerStatistics.a16'),
                },
                
                name: {
                    dataIndex: 'name',
                    key: 'name',
                    title: this.$t('customerStatistics.a21'),
                },
                siteGRCount:{
                    title: this.$t('customerStatistics.a4'),
                    dataIndex: 'siteGRCount',
                    key: 'siteGRCount',
                    align:'right',
                    scopedSlots: { customRender: 'siteGRCount' },
                },
                siteCount:    {
                    title: this.$t('customerStatistics.a5'),
                    dataIndex: 'siteCount',
                    key: 'siteCount',
                    align:'right',
                    scopedSlots: { customRender: 'siteCount' },
                },
                serviceCount: {
                    title: this.$t('customerStatistics.a6'),
                    key: 'serviceCount',
                    dataIndex: 'serviceCount',
                    align:'right',
                    scopedSlots: { customRender: 'serviceCount' },
                },
                tenantCount:{
                    title: this.$t('customerStatistics.a7'),
                    key: 'tenantCount',
                    dataIndex: 'tenantCount',
                    align:'right',
                    scopedSlots: { customRender: 'tenantCount' },
                },
                count:{
                    title: this.$t('customerStatistics.a8'),
                    dataIndex: 'count',
                    key: 'count',
                    align:'right',
                    scopedSlots: { customRender: 'count' },
                }
            },
            statistics:{

                clientCount: 0,
                siteGRCount: 0,
                siteCount: 0,
                tenantCount: 0,
                serviceCount: 0,
                equipmentCount:0,
            },
            col:3,
            kind:0,
            count:0,
            clientId:undefined,
            sitegrId:undefined,
            siteId:undefined,
            serviceId:undefined,
            
            //
            // titles:['','客户信息','建筑信息','站点信息','服务信息','楼层/租户信息','设备信息'],
            titles:['',this.$t('customerStatistics.a2'),this.$t('customerStatistics.a17'),this.$t('customerStatistics.a22'),this.$t('customerStatistics.a39')
            ,this.$t('customerStatistics.a25'),this.$t('customerStatistics.a40')],
            drawerTitle:'',
            steps:[undefined,undefined,undefined,undefined,undefined,undefined,undefined],
            width:800,
            clientTable:{
                clients:[],
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) => {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.clientTable.pagination.current = current;
                        this.clientTable.pagination.pageSize = size;
                    // console.log("onChange current", current);
                    },
                },
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.clientTable.pagination.current - 1) * this.clientTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'clientName',
                        key: 'clientName',
                        title: this.$t('customerStatistics.a15'),
                        width: 240,
                    },
                    {
                        title: this.$t('customerStatistics.a4'),
                        dataIndex: 'siteGRCount',
                        key: 'siteGRCount',
                        align:'right',
                        scopedSlots: { customRender: 'siteGRCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a5'),
                        dataIndex: 'siteCount',
                        key: 'siteCount',
                        align:'right',
                        scopedSlots: { customRender: 'siteCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a6'),
                        key: 'serviceCount',
                        dataIndex: 'serviceCount',
                        align:'right',
                        scopedSlots: { customRender: 'serviceCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a7'),
                        key: 'tenantCount',
                        dataIndex: 'tenantCount',
                        align:'right',
                        scopedSlots: { customRender: 'tenantCount' },
                    }
                ],
                size: { y: 540 },
            },
            siteGrTable:{
                siteGrs:[],
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    }  ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.siteGrTable.pagination.current = current;
                        this.siteGrTable.pagination.pageSize = size;
                        // console.log("onChange current", current);
                    },
                }, 
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.siteGrTable.pagination.current - 1) * this.siteGrTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'clientName',
                        key: 'clientName',
                        title: this.$t('customerStatistics.a15'),
                    },
                    {
                        dataIndex: 'siteGrName',
                        key: 'siteGrName',
                        title: this.$t('customerStatistics.a16'),
                    },
                    
                    {
                        title: this.$t('customerStatistics.a5'),
                        dataIndex: 'siteCount',
                        key: 'siteCount',
                        align:'right',
                        scopedSlots: { customRender: 'siteCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a6'),
                        key: 'serviceCount',
                        dataIndex: 'serviceCount',
                        align:'right',
                        scopedSlots: { customRender: 'serviceCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a7'),
                        key: 'tenantCount',
                        dataIndex: 'tenantCount',
                        align:'right',
                        scopedSlots: { customRender: 'tenantCount' },
                    }
                ],
                size: { y: 540 },
            },
            siteTable:{
                sites:[],
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.siteTable.pagination.current = current;
                        this.siteTable.pagination.pageSize = size;
                        // console.log("onChange current", current);
                    },
                }, 
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.siteTable.pagination.current - 1) * this.siteTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'clientName',
                        key: 'clientName',
                        title: this.$t('customerStatistics.a15'),
                    },
                    {
                        dataIndex: 'siteGrName',
                        key: 'siteGrName',
                        title: this.$t('customerStatistics.a16'),
                    },
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: this.$t('customerStatistics.a21'),
                    },
                    
                    {
                        title: this.$t('customerStatistics.a6'),
                        key: 'serviceCount',
                        dataIndex: 'serviceCount',
                        align:'right',
                        scopedSlots: { customRender: 'serviceCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a7'),
                        key: 'tenantCount',
                        dataIndex: 'tenantCount',
                        align:'right',
                        scopedSlots: { customRender: 'tenantCount' },
                    },
                    
                ],
                size: { y: 540 },
            },
            serviceTable:{
                services:[],
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.serviceTable.pagination.current = current;
                        this.serviceTable.pagination.pageSize = size;
                        // console.log("onChange current", current);
                    },
                }, 
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.serviceTable.pagination.current - 1) * this.serviceTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'clientName',
                        key: 'clientName',
                        title: this.$t('customerStatistics.a15'),
                    },
                    {
                        dataIndex: 'siteGrName',
                        key: 'siteGrName',
                        title: this.$t('customerStatistics.a16'),
                    },
                    
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: this.$t('customerStatistics.a21'),
                    },
                    {
                        title: this.$t('customerStatistics.a14'),
                        key: 'siteServiceTypeName',
                        dataIndex: 'siteServiceTypeName',
                    }
                ],
                size: { y: 540 },
            },
            tenantTable:{
                tenants:[],
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.tenantTable.pagination.current = current;
                        this.tenantTable.pagination.pageSize = size;
                        // console.log("onChange current", current);
                    },
                }, 
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.tenantTable.pagination.current - 1) * this.tenantTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'clientName',
                        key: 'clientName',
                        title: this.$t('customerStatistics.a15'),
                    },
                    {
                        dataIndex: 'siteGrName',
                        key: 'siteGrName',
                        title: this.$t('customerStatistics.a16'),
                    },
                    
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: this.$t('customerStatistics.a21'),
                    },
                    {
                        title: this.$t('customerStatistics.a26'),
                        key: 'tenantName',
                        dataIndex: 'tenantName',
                    }
                ],
                size: { y: 540 },
            },
            serviceStatisticsTable:{
                list:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        this.serviceStatisticsTable.pagination.current = current;
                        this.serviceStatisticsTable.pagination.pageSize = size;
                        // console.log("onChange current", current);
                    },
                }, 
                no:{
                    title: "NO",          
                    dataIndex: "NO",
                    width: 60,
                },
                columns:[
                ],
                size: { y: 540 },
            },
            serviceCountTable:{
                serviceCountInfoList:[],
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                    this.serviceCountTable.pagination.current = current;
                    this.serviceCountTable.pagination.pageSize = size;
                    // console.log("onChange current", current);
                    },
                },
                size: {  y: 480 },
                columns:[
                    {
                        title: "NO",          
                        dataIndex: "NO",
                        customRender: (text, record, index) =>
                            `${
                            (this.serviceCountTable.pagination.current - 1) * this.serviceCountTable.pagination.pageSize +
                            index +
                            1
                            }`,
                        width: 60,
                    },
                    {
                        dataIndex: 'siteServiceTypeName',
                        key: 'siteServiceTypeName',
                        title: this.$t('customerStatistics.a14'),
                    },
                    {
                        title: this.$t('customerStatistics.a3'),
                        dataIndex: 'clientCount',
                        key: 'clientCount',
                        align:'right',
                        scopedSlots: { customRender: 'clientCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a4'),
                        dataIndex: 'siteGRCount',
                        key: 'siteGRCount',
                        align:'right',
                        scopedSlots: { customRender: 'siteGRCount' },
                    },
                    {
                        title: this.$t('customerStatistics.a5'),
                        dataIndex: 'siteCount',
                        key: 'siteCount',
                        align:'right',
                        scopedSlots: { customRender: 'siteCount' },
                    }
                ],
            },
            equipmentCountTable:{
                equipmentCountInfoList:[],
                no:{
                    title: "NO",          
                    dataIndex: "NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.equipmentCountTable.pagination.current - 1) * this.equipmentCountTable.pagination.pageSize +
                        index +
                        1
                        }`,
                    width: 60,
                },
                columns:[],
                size: {  y: 480 },
                pagination: {
                    total: 0, //数据总数
                    pageSize: 10, //每页中显示30条数据
                    // showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                    showTotal:(total, range) =>  {
                        // return `当前${range[0]}-${range[1]} /总共 ${total} ${this.$t("alarm.a8")}`;
                        return this.showTotalText(total, range);
                    } ,
                    showQuickJumper: true, //是否可以快速跳转至某页
                    defaultCurrent: 1, //默认当前页面数
                    current:1,//当前页面
                    hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
                    // 改变每页数量时更新显示
                    onChange: (current, size) => {
                        // console.log("onChange current", current);
                        this.equipmentCountTable.pagination.current = current;
                        this.equipmentCountTable.pagination.pageSize = size;
                    },
                },
                filterType:[
                    {value:"TOTAL-ICON",	name: this.$t('customerStatistics.a27'),kind:0},//所有设备类型分组合计
                    {value:"CLIENT-ICON",   name: this.$t('customerStatistics.a28'),kind:1},//客户设备类型分组合计
                    {value:"SITEGR-ICON",  	name: this.$t('customerStatistics.a29'),kind:2},//建筑设备类型分组合计
                    {value:"SITE-ICON",  	name: this.$t('customerStatistics.a30'),kind:3},//站点设备类型分组合计
                    // {value:"TOTAL-SUM",		name:this.$t('customerStatistics.a31'),kind:0},//所有设备合计
                    {value:"CLIENT-SUM",     name:this.$t('customerStatistics.a32'),kind:1},//客户设备合计
                    {value:"SITEGR-SUM",     name:this.$t('customerStatistics.a33'),kind:2},//建筑设备合计
                    {value:"SITE-SUM",       name:this.$t('customerStatistics.a34'),kind:3},//站点设备合计
                    // {value:"TOTAL-DETAIL",   name:this.$t('customerStatistics.a35'),kind:0},//所有站点设备详情
                    // {value:"CLIENT-DETAIL",  name:this.$t('customerStatistics.a36'),kind:1},//客户站点设备详情
                    // {value:"SITEGR-DETAIL",  name:this.$t('customerStatistics.a37'),kind:2},//建筑站点设备详情
                    {value:"SITE-DETAIL",    name:this.$t('customerStatistics.a38'),kind:3},//站点设备详情
                ],
                kind:0,
                type:'TOTAL-ICON',
                loading:false,
                clients:[],
                siteGrs:[],
                sites:[],
                icons:[],
                clientId:undefined,
                sitegrId:undefined,
                siteId:undefined,
                icon:undefined,
                clientName:undefined,
                siteGrName:undefined,
                siteName:undefined,
                iconName:undefined,
                stepMaps:new Map(),
                steps:[undefined,undefined,undefined,undefined,undefined,undefined,undefined],
                count:0,
            },
            
        }
    },
    mounted(){
        let that = this;
        this.getCustomerStatisticsData();
        this.tableSize();
        window.addEventListener("resize", this.tableSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.tableSize);
        clearInterval(this.timer);
    },
    methods:{
        showTotalText(total, range){
            let format = this.$t('customerStatistics.a43');
            format = format.replace('$1',range[0]);
            format = format.replace('$2',range[1]);
            format = format.replace('$3',total);
            return format;
        },
        dataFormat(val,bit){
            if(val){
                if(bit>0){
                    return Number(Number(val).toFixed(bit)).toLocaleString();
                }else{

                    return Number(val).toLocaleString();
                }
            }
            return val;
        },
        // 表格自适应方法
        tableSize() {
            let width = document.body.clientWidth;
            let height = this.$refs.main3.clientHeight;
            let tableHeight = height - 160;
            this.equipmentCountTable.size.y = tableHeight;
            this.serviceCountTable.size.y = height;
            this.width = width / 2 ;
            if(this.width-50>600){

                // this.clientTable.size.x = undefined;
            }

        },
        showDrawer(kind) {
            this.drawerTitle = this.titles[kind];
            this.kind = kind;
            this.count = 0;
            this.filterData();
            this.visible = true;
        },
        onClose() {
            this.visible = false;
            this.kind = 0;
            this.clientTable.list = [];
            this.siteGrTable.list = [];
            this.siteTable.list = [];
            this.serviceTable.list = [];
            this.tenantTable.list = [];

            this.clientId = undefined;
            this.sitegrId = undefined;
            this.siteId   = undefined;
            this.serviceId   = undefined;
            this.count = 0;

            this.steps = [undefined,undefined,undefined,undefined,undefined,undefined,undefined];
        },
        siteFilterData(){
            this.filterData();
        },
        grFilterData(){
            this.siteId = undefined;
            this.filterData();
        },
        clientFilterData(){
            this.siteId = undefined;
            this.sitegrId = undefined;
            this.filterData();
        },
        filterData(){
            let arr = [];
            if(this.kind===1){
                arr = JSON.parse(JSON.stringify(this.clientTable.clients));
                this.clientTable.list = [];
            } else if(this.kind===2){
                arr = JSON.parse(JSON.stringify(this.siteGrTable.siteGrs));
                this.siteGrTable.list = [];
            } else if(this.kind===3){
                arr = JSON.parse(JSON.stringify(this.siteTable.sites));
                this.siteTable.list = [];
            } else if(this.kind===4){
                arr = JSON.parse(JSON.stringify(this.serviceTable.services));
                this.serviceTable.list = [];
            } else if(this.kind===5){
                arr = [];
                this.tenantTable.list = [];
            } 
           let tsts = [];
           let grs = [this.initData.siteGr];

            if(this.clientId){
                this.siteGrTable.siteGrs.forEach(element => {
                    if(element.clientId === this.clientId){
                        grs.push(element);
                    }
                });

                this.siteTable.sites.forEach(element => {
                    if(element.clientId === this.clientId){
                        tsts.push(element);
                    }
                });

            }else{
                tsts = [...this.siteTable.sites];
                grs = [...grs,...this.siteGrTable.siteGrs];
            }

          
            let sts = [this.initData.site];
            if(this.sitegrId ){
                tsts.forEach(element => {
                    if(element.sitegrId === this.sitegrId){
                        sts.push(element);
                    }
                });
            }else{
                sts = [...sts,...tsts];
            }

            // console.log('filterData',JSON.stringify(grs));
            // console.log('filterData',JSON.stringify(sts));

            this.initData.siteGrs = grs;
            this.initData.sites = sts;

            let arr2 = [];
            arr.forEach(element => {
                let bol = true;
                if(bol && this.clientId){
                    if(element.clientId === this.clientId){
                        bol = true;
                    } else {
                        bol = false;
                    }
                } 
                if(bol  && this.sitegrId){
                    if(element.sitegrId === this.sitegrId){
                        bol = true;
                    } else {
                        bol = false;
                    }
                }
                if(bol  && this.siteId){
                    if(element.siteId === this.siteId){
                        bol = true;
                    } else {
                        bol = false;
                    }
                }

                if(bol  && this.serviceId && this.kind===4){
                    if(element.siteServiceTypeID === this.serviceId){
                        bol = true;
                    } else {
                        bol = false;
                    }
                }

                if(bol){
                    arr2.push(element);
                }
            });
            // console.log('filterData',JSON.stringify(arr2));
            this.count = arr2.length;
            if(this.kind===1){
                this.clientTable.list = arr2;
                this.clientTable.pagination.current  = 1;
            } else if(this.kind===2){
                this.siteGrTable.list = arr2;
                this.siteGrTable.pagination.current  = 1;
            } else if(this.kind===3){
                this.siteTable.list = arr2;
                this.siteTable.pagination.current  = 1;
            } else if(this.kind===4){
                this.serviceTable.list = arr2;
                this.serviceTable.pagination.current  = 1;
            } else if(this.kind===5){
               this.getTenantDetailData();
            } 
        },
        getTenantDetailData(){
            let cids = [];
            let gids = [];
            let sids = [];
            let icons = [];
            
            
            let bol = true;
            if(this.clientId){
                cids.push(this.clientId);
                bol = false;
            }
            if(this.sitegrId){
                gids.push(this.sitegrId);
                bol = false;
            }
            if(this.siteId){
                sids.push(this.siteId);
                bol = false;
            }else{
                if(this.sitegrId){
                    this.siteTable.sites.forEach(element => {
                        if(this.sitegrId === element.sitegrId){
                            sids.push(element.siteId);
                            bol = false;
                        }
                    });
                } else if(!this.sitegrId && this.clientId ){

                    this.siteTable.sites.forEach(element => {
                        if(this.clientId === element.clientId){
                            sids.push(element.siteId);
                            bol = false;
                        }
                    });

                    
                }
            }
            
            if(bol){
                this.siteTable.sites.forEach(element => {
                    sids.push(element.siteId);
                });
            }

            let param ={
                type:'TENANT-DETAIL',
                clientIds:cids,
                siteGrIds:gids,
                siteIds:sids
            }
            this.loading = true;
            getCustomerTenantDetailData(param)
            .then((res) => {
                // console.log('getCustomerEquipmentStatistics result',res);
                if(res.errorCode ==='00' && res.data){
                    this.tenantTable.pagination.current = 1;
                    this.tenantTable.list = res.data.children;
                    this.count = res.data.count;
                } else{
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                // console.log(err);
            });
        },
        drillingEquipmentCount(record){
            // console.log('drilling',record);
            let pkind = this.equipmentCountTable.kind;
            let name = undefined;
           
            let exrecord = {
                clientId : this.equipmentCountTable.clientId,
                sitegrId : this.equipmentCountTable.sitegrId,
                siteId   : this.equipmentCountTable.siteId,
                type     : this.equipmentCountTable.icon
            };
            let bol = false;
            if(this.equipmentCountTable.type === 'TOTAL-ICON'){
                this.equipmentCountTable.icon = record.type;
                this.equipmentCountTable.type = 'CLIENT-ICON';
                this.equipmentCountTable.kind = 1;
                bol = true;
                this.equipmentCountTable.iconName = record.title;

                let step = {record:exrecord,name:this.equipmentCountTable.iconName,type:'TOTAL-ICON'};
                this.equipmentCountTable.steps[1] = step;

            } else if(this.equipmentCountTable.type === 'CLIENT-ICON'){

                this.equipmentCountTable.clientId = record.clientId;
                this.equipmentCountTable.icon = record.type;
                this.equipmentCountTable.type = 'SITEGR-ICON';
                this.equipmentCountTable.kind = 2;
                this.equipmentCountTable.clientName = record.clientName;
                bol = true;
                
                let step = {record:exrecord,name:this.equipmentCountTable.clientName,type:'CLIENT-ICON'};
                this.equipmentCountTable.steps[2] = step;
                this.filterClientChange();

            } else if(this.equipmentCountTable.type === 'SITEGR-ICON'){
                this.equipmentCountTable.clientId = record.clientId;
                this.equipmentCountTable.sitegrId = record.sitegrId;
                this.equipmentCountTable.icon     = record.type;
                this.equipmentCountTable.type = 'SITE-ICON';
                this.equipmentCountTable.kind = 3;
                this.equipmentCountTable.siteGrName = record.siteGrName;
                bol = true;

                let step = {record:exrecord,name:this.equipmentCountTable.siteGrName,type:'SITEGR-ICON'};
                this.equipmentCountTable.steps[3] = step;
                this.filterSiteGrChange();

            } else if(this.equipmentCountTable.type === 'SITE-ICON'){
                
                this.equipmentCountTable.kind = 4;
                this.equipmentCountTable.siteName = record.siteName;
                bol = true;

                let step = {record:exrecord,name:this.equipmentCountTable.siteName,type:'SITE-ICON'};
                this.equipmentCountTable.steps[4] = step;

            } else if(this.equipmentCountTable.type === 'CLIENT-SUM'){
                this.equipmentCountTable.clientId = record.clientId;
                this.equipmentCountTable.icon = record.type;
                this.equipmentCountTable.type = 'SITEGR-SUM';
                this.equipmentCountTable.kind = 2;
                this.equipmentCountTable.clientName = record.clientName;
                bol = true;

                let step = {record:exrecord,name:this.equipmentCountTable.clientName,type:'CLIENT-SUM'};
                this.equipmentCountTable.steps[2] = step;
                this.filterClientChange();

            } else if(this.equipmentCountTable.type === 'SITEGR-SUM'){
                this.equipmentCountTable.clientId = record.clientId;
                this.equipmentCountTable.sitegrId = record.sitegrId;
                this.equipmentCountTable.icon     = record.type;
                this.equipmentCountTable.type = 'SITE-SUM';
                this.equipmentCountTable.kind = 3;
                this.equipmentCountTable.siteGrName = record.siteGrName;
                bol = true;

                let step = {record:exrecord,name:this.equipmentCountTable.siteGrName,type:'SITEGR-SUM'};
                
                this.equipmentCountTable.steps[3] = step;
                this.filterSiteGrChange();
            }
            if(bol){
                this.searchEquipmentCountInfo();
            }
        },
        rollUpEquipmentCount(setp){
            
            let bol = false;
            if(setp.type === 'TOTAL-ICON'){
                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 0;
                this.equipmentCountTable.siteGrName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps = [undefined,undefined,undefined,undefined,undefined,undefined,undefined];

            } else if(setp.type === 'CLIENT-ICON'){

                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 1;
                this.equipmentCountTable.siteGrName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps[2] = undefined;
                this.equipmentCountTable.steps[3] = undefined;
                this.equipmentCountTable.steps[4] = undefined;
                this.filterClientChange();

            } else if(setp.type === 'SITEGR-ICON'){
                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 2;
                this.equipmentCountTable.siteName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps[3] = undefined;
                this.equipmentCountTable.steps[4] = undefined;
                this.filterSiteGrChange();

            } else if(setp.type === 'SITE-ICON'){
                
                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 3;
                this.equipmentCountTable.siteName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps[4] = undefined;
                this.filterSiteChange();

            } else if(setp.type === 'CLIENT-SUM'){
                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 1;
                this.equipmentCountTable.siteGrName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps[2] = undefined;
                this.equipmentCountTable.steps[3] = undefined;
                this.equipmentCountTable.steps[4] = undefined;
                this.filterClientChange();

            } else if(setp.type === 'SITEGR-SUM'){
                this.equipmentCountTable.clientId = setp.record.clientId;
                this.equipmentCountTable.sitegrId = setp.record.sitegrId;
                this.equipmentCountTable.siteId   = setp.record.siteId;
                this.equipmentCountTable.icon     = setp.record.type;
                this.equipmentCountTable.type     = setp.type;
                this.equipmentCountTable.kind     = 2;
                this.equipmentCountTable.siteGrName = setp.name;
                bol = true;                
                this.equipmentCountTable.steps[3] = undefined;
                this.equipmentCountTable.steps[4] = undefined;
                this.filterSiteGrChange();
            }
            if(bol){
                this.searchEquipmentCountInfo();
            }
        },
        rollUp(){
            let preStep = this.steps[this.kind] ;
           
            this.rollUpCustomerStatistics(preStep);
           
        },
        rollUpCustomerStatistics(preStep){
            // console.log('RollUp preStep',preStep);
            if(preStep){
                this.steps[this.kind] = undefined;
                
                this.clientId = preStep.record.clientId;
                this.sitegrId = preStep.record.sitegrId;
                this.siteId   = preStep.record.siteId;
                this.showDrawer(preStep.kind);
            }
        },
        drillingServiceStatistics(record,kind){
            
            this.count = 0;
            let type = record.siteServiceTypeID;
            this.serviceId = type;
            
            let name = "";
            if(kind === 1){

                this.serviceStatisticsTable.columns = [];
                this.serviceStatisticsTable.columns.push(this.serviceStatisticsTable.no);
                this.serviceStatisticsTable.columns.push(this.columnMap.clientName);
                let cids = [];
                let arr = [];
                let cMap = new Map();
                this.serviceTable.services.forEach(element => {
                    if(element.siteServiceTypeID === type){
                        if(!cMap.get(element.clientId)){
                            name = element.siteServiceTypeName;
                            cids.push(element.clientId);
                            cMap.set(element.clientId,element.clientId);
                        }
                    }
                });
                console.log(cids);
                let no = 1;
                this.clientTable.clients.forEach(element => {
                    cids.forEach(id => {
                        if(id === element.clientId){
                            arr.push({NO:no,clientId:element.clientId,clientName:element.clientName});
                            no++;
                        }
                    });
                });

                console.log(arr);
                this.serviceStatisticsTable.list = arr;
                this.serviceStatisticsTable.pagination.current = 1;
                this.count = arr.length;
                this.kind = 6;
            } else if(kind === 2){
                this.serviceStatisticsTable.columns = [];
                this.serviceStatisticsTable.columns.push(this.serviceStatisticsTable.no);
                this.serviceStatisticsTable.columns.push(this.columnMap.clientName);
                this.serviceStatisticsTable.columns.push(this.columnMap.siteGrName);

                let cids = [];
                let arr = [];
                let cMap = new Map();

                this.serviceTable.services.forEach(element => {
                    if(element.siteServiceTypeID === type){
                        if(!cMap.get(element.sitegrId)){
                            name = element.siteServiceTypeName;
                            cids.push(element.sitegrId);
                            cMap.set(element.sitegrId,element.sitegrId);
                        }
                    }
                });
                console.log(cids);
                let no = 1;
                this.siteGrTable.siteGrs.forEach(element => {
                    cids.forEach(id => {
                        if(id === element.sitegrId){
                            arr.push({NO:no,clientId:element.clientId,clientName:element.clientName,sitegrId:element.sitegrId,siteGrName:element.siteGrName});
                            no++;
                        }
                    });
                });

                console.log(arr);
                this.serviceStatisticsTable.list = arr;
                this.serviceStatisticsTable.pagination.current = 1;
                this.count = arr.length;
                this.kind = 6;

            } else if(kind === 3){
                // this.showDrawer(4)；
                this.serviceStatisticsTable.columns = [];
                this.serviceStatisticsTable.columns.push(this.serviceStatisticsTable.no);
                this.serviceStatisticsTable.columns.push(this.columnMap.clientName);
                this.serviceStatisticsTable.columns.push(this.columnMap.siteGrName);
                this.serviceStatisticsTable.columns.push(this.columnMap.name);
                let cids = [];
                let arr = [];
                let cMap = new Map();

                this.serviceTable.services.forEach(element => {
                    if(element.siteServiceTypeID === type){
                        if(!cMap.get(element.siteId)){
                            name = element.siteServiceTypeName;
                            cids.push(element.siteId);
                            cMap.set(element.siteId,element.siteId);
                        }
                    }
                });
                console.log(cids);
                let no = 1;
                this.siteTable.sites.forEach(element => {
                    cids.forEach(id => {
                        if(id === element.siteId){
                            arr.push({NO:no,clientId:element.clientId,clientName:element.clientName
                                ,sitegrId:element.sitegrId,siteGrName:element.siteGrName
                                ,siteId:element.siteId,name:element.name});
                            no++;
                        }
                    });
                });

                console.log(arr);
                this.serviceStatisticsTable.list = arr;
                this.serviceStatisticsTable.pagination.current = 1;
                this.count = arr.length;
                this.kind = 6;

            }
            if(''!==name){
                this.drawerTitle = name+" / "+this.titles[kind];
            }else{
                this.drawerTitle = this.titles[kind];
            }
            this.visible = true;
        },
        drillingCustomerStatistics(record,kind){
            console.log('drillingCustomerStatistics',record,kind);
            let name = undefined;
            let pkind = this.kind;
            let exrecord = {
                clientId : this.clientId,
                sitegrId : this.sitegrId,
                siteId :   this.siteId
            };
           
            let bol = false;
            this.kind = kind;
            if(kind === 2){
                name = record.clientName;
                this.clientId = record.clientId;
                this.sitegrId = undefined;
                this.siteId = undefined;
                bol = true;
            } else if(kind === 3){
                this.clientId = record.clientId;
                this.sitegrId = record.sitegrId;
                this.siteId = undefined;
                
                if(record.siteGrName){
                    name = record.siteGrName;
                } else if(record.clientName){
                    name = record.clientName;
                }
                bol = true;
            } else if(kind === 4){

                this.clientId = record.clientId;
                this.sitegrId = record.sitegrId;
                this.siteId   = record.siteId;

                if(record.name){
                    name = record.name;
                }else if(record.siteGrName){
                    name = record.siteGrName;
                } else if(record.clientName){
                    name = record.clientName;
                }
                bol = true;
            } else if(kind === 5){
                this.clientId = record.clientId;
                this.sitegrId = record.sitegrId;
                this.siteId = record.siteId;
                if(record.kind==='Client'){

                }

                if(record.name){
                    name = record.name;
                }else if(record.siteGrName){
                    name = record.siteGrName;
                } else if(record.clientName){
                    name = record.clientName;
                }
                bol = true;
            }
            if(bol){
                exrecord.name = name;
                let preStep = {
                    kind:pkind,
                    record:exrecord,
                };
                this.steps[kind] = preStep;
                this.showDrawer(kind);
            }
        },
        getCustomerStatisticsData(){
            this.loading = true;
            getCustomerStatistics()
            .then((res) => {
                // console.log('getCustomerStatisticsData result',res);
                if(res.errorCode ==='00' && res.data){
                    this.statistics = res.data.statistics;
                    this.clientTable.clients = res.data.clients;
                    this.siteGrTable.siteGrs = res.data.siteGrs ;
                    this.siteTable.sites = res.data.sites;
                    this.serviceTable.services = res.data.services;
                    let key = 1;
                    this.serviceTable.services.forEach(element => {
                        element.key = key++;
                    });
                    this.initData.clients =[this.initData.client,...res.data.clients] ;
                    this.initData.siteGrs = [this.initData.siteGr,...res.data.siteGrs ] ;
                    this.initData.sites = [this.initData.site,...res.data.sites] ;
                    


                    this.equipmentCountTable.clients    =  [this.initData.client,...res.data.clients] ;
                    this.equipmentCountTable.siteGrs    =  [this.initData.siteGr,...res.data.siteGrs ] ;
                    this.equipmentCountTable.sites      =  [this.initData.site,...res.data.sites] ;

                    this.serviceCountTable.serviceCountInfoList = res.data.serviceCountInfoList;
                    this.initData.services = [this.initData.service] ;
                    this.serviceCountTable.serviceCountInfoList.forEach(element => {
                        let item = {siteServiceTypeId:element.siteServiceTypeID,siteServiceTypeName:element.siteServiceTypeName};
                        this.initData.services.push(item);
                    });
                  

                    this.getCustomerEquipmentStatisticsData("TOTAL-ICON");
                } else{

                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                // console.log(err);
            });
        },
        setCustomerEquipmentStatisticsData(tag,type,res){
            if(tag===0){
                if(type==='TOTAL-ICON'){
                    this.statistics.equipmentCount = res.data.count;
                    this.equipmentCountTable.equipmentCountInfoList = res.data.equipmentCountInfoList;
                    let key = 1;
                    this.equipmentCountTable.icons = [];
                    this.equipmentCountTable.icons.push({value:undefined,name:this.$t('customerStatistics.a20')}); //'请选择'
                    this.equipmentCountTable.equipmentCountInfoList.forEach(element => {
                        element.key = ++key;
                        let icon = {value:element.type,name:element.title};
                        this.equipmentCountTable.icons.push(icon);
                    });
                    this.equipmentCountTable.columns = [];
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                    this.equipmentCountTable.pagination.current = 1;
                    this.equipmentCountTable.count = res.data.count;
                } else if(type==='TOTAL-SUM'){
                    this.statistics.equipmentCount = res.data.count;
                }

            } else {
                this.equipmentCountTable.columns = [];
                this.equipmentCountTable.equipmentCountInfoList = res.data.equipmentCountInfoList;
                this.equipmentCountTable.pagination.current = 1;
                let key = 1;
                this.equipmentCountTable.equipmentCountInfoList.forEach(element => {
                    element.key = key++;
                });
                
                this.equipmentCountTable.count = res.data.count;
                if(type==='CLIENT-COUNT' || type==='CLIENT-SUM'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                }  else  if(type==='CLIENT-ICON'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else  if(type==='CLIENT-DETAIL'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else  if(type==='TOTAL-ICON'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else  if(type==='TOTAL-DETAIL'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else if(this.type==='TOTAL-COUNT' || this.type==='TOTAL-SUM'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else if(type==='SITEGR-COUNT' || type==='SITEGR-SUM'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                }  else  if(type==='SITEGR-ICON'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else  if(type==='SITEGR-DETAIL'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                }  else if(type==='SITE-COUNT' || type==='SITE-SUM'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                }  else  if(type==='SITE-ICON'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                } else  if(type==='SITE-DETAIL'){
                    this.equipmentCountTable.columns.push(this.equipmentCountTable.no);
                    this.equipmentCountTable.columns.push(this.columnMap.clientName);
                    this.equipmentCountTable.columns.push(this.columnMap.siteGrName);
                    this.equipmentCountTable.columns.push(this.columnMap.name);
                    this.equipmentCountTable.columns.push(this.columnMap.title);
                    this.equipmentCountTable.columns.push(this.columnMap.count);
                }  else {
                    
                    
                }

            }

        },
        getCustomerEquipmentStatisticsData(type){
            let ids = [];
            this.siteTable.sites.forEach(element => {
                ids.push(element.siteId);
            });

            let param ={
                type:type,//'TOTAL-ICON'
                siteIds:ids,
                icons:[],
            }
            this.equipmentCountTable.loading = true;
            getCustomerEquipmentStatistics(param)
            .then((res) => {
                // console.log('getCustomerEquipmentStatistics result',res);
                if(res.errorCode ==='00' && res.data){
                    this.setCustomerEquipmentStatisticsData(0,type,res);
                } else{
                    this.$message.error(res.msg);
                }
                this.equipmentCountTable.loading = false;
            })
            .catch((err) => {
                this.equipmentCountTable.loading = false;
                // console.log(err);
            });
        },
        getEquipmentCountInfo(){
            let ids = [];
            this.siteTable.sites.forEach(element => {
                ids.push(element.siteId);
            });

            let param ={
                type:this.equipmentCountTable.type,//'TOTAL-ICON'
                siteIds:ids,
                icons:[],
            }
            this.equipmentCountTable.loading = true;
            getCustomerEquipmentStatistics(param)
            .then((res) => {
                // console.log('getCustomerEquipmentStatistics result',res);
                if(res.errorCode ==='00' && res.data){
                    this.setCustomerEquipmentStatisticsData(1,this.equipmentCountTable.type,res)
                } else {
                    this.$message.error(res.msg);
                }
                this.equipmentCountTable.loading = false;
            })
            .catch((err) => {
                this.equipmentCountTable.loading = false;
                // console.log(err);
            });
        },
        filterTypeChange(val,e){
            console.log(val,e.key);
            let t = this.equipmentCountTable.filterType[e.key];
            this.equipmentCountTable.kind = t.kind;
            this.equipmentCountTable.icon = undefined;
            this.equipmentCountTable.siteId = undefined;
            this.equipmentCountTable.sitegrId = undefined;
            this.equipmentCountTable.clientId = undefined;
            this.equipmentCountTable.steps = [undefined,undefined,undefined,undefined,undefined,undefined,undefined];
            this.equipmentCountTable.equipmentCountInfoList = [];
            this.equipmentCountTable.count = 0;
            this.filterClientChange();
        },
        filterClientChange(){
            this.equipmentCountTable.sitegrId = undefined;
            this.equipmentCountTable.siteId = undefined;
            let grs = [this.initData.siteGr];
            let sts = [this.initData.site];
            //&& this.equipmentCountTable.clientId!==''
            if(this.equipmentCountTable.clientId ){
                this.siteGrTable.siteGrs.forEach(element => {
                    if(element.clientId === this.equipmentCountTable.clientId){
                        this.equipmentCountTable.clientName = element.clientName;
                        grs.push(element);
                    }
                });

                this.siteTable.sites.forEach(element => {
                    if(element.clientId === this.equipmentCountTable.clientId){
                        sts.push(element);
                    }
                });

            }else{
                grs = [...grs,...this.siteGrTable.siteGrs];
                sts = [...sts,...this.siteTable.sites];
                this.equipmentCountTable.clientName = undefined;
            }

            this.equipmentCountTable.siteGrs = grs;
            this.equipmentCountTable.sites = sts;
            
            // this.searchEquipmentCountInfo();
        },
        filterSiteGrChange(){
            this.equipmentCountTable.siteId = undefined;
            let sts = [this.initData.site];
            if(this.equipmentCountTable.sitegrId ){
                this.siteTable.sites.forEach(element => {
                    if(element.sitegrId === this.equipmentCountTable.sitegrId){
                        this.equipmentCountTable.siteGrName = element.siteGrName;
                        sts.push(element);
                    }
                });
            }else{
                sts = [...sts,...this.siteTable.sites];
                this.equipmentCountTable.siteGrName = undefined;
            }
            this.equipmentCountTable.sites = sts;
            
            // this.searchEquipmentCountInfo();
        },
        filterSiteChange(){
            if(this.equipmentCountTable.siteId){
                this.siteTable.sites.forEach(element => {
                    if(element.siteId === this.equipmentCountTable.siteId){
                        this.equipmentCountTable.siteName = element.siteName;
                    }
                });
            }
        },
        filterIconChange(){
            if(this.equipmentCountTable.icon){
                this.equipmentCountTable.icons.forEach(element => {
                    if(element.value === this.equipmentCountTable.icon){
                        this.equipmentCountTable.iconName = element.name;
                    }
                });
            }
        },
        searchEquipmentCountInfo(){
            let cids = [];
            let gids = [];
            let sids = [];
            let icons = [];
            
            
            let bol = true;
            if(this.equipmentCountTable.clientId){
                cids.push(this.equipmentCountTable.clientId);
                bol = false;
            }
            if(this.equipmentCountTable.sitegrId){
                gids.push(this.equipmentCountTable.sitegrId);
                bol = false;
            }
            if(this.equipmentCountTable.siteId){
                sids.push(this.equipmentCountTable.siteId);
                bol = false;
            }
            if(this.equipmentCountTable.icon){
                icons.push(this.equipmentCountTable.icon);
            }

            if(bol){
                this.siteTable.sites.forEach(element => {
                    sids.push(element.siteId);
                });
            }

            let param ={
                type:this.equipmentCountTable.type,//'TOTAL-ICON'
                clientIds:cids,
                siteGrIds:gids,
                siteIds:sids,
                icons:icons,
            }
            this.equipmentCountTable.loading = true;
            getCustomerEquipmentStatistics(param)
            .then((res) => {
                // console.log('getCustomerEquipmentStatistics result',res);
                if(res.errorCode ==='00' && res.data){
                    this.setCustomerEquipmentStatisticsData(1,this.equipmentCountTable.type,res);
                } else{
                    this.$message.error(res.msg);
                }
                this.equipmentCountTable.loading = false;
            })
            .catch((err) => {
                this.equipmentCountTable.loading = false;
                // console.log(err);
            });
        }
    }
}

</script>

<style scoped>
#CustomerStatisticsPage{
    width: 100%;
    height: 100%;
    background-color: #f2f3f8;
    /* font-family: "Microsoft YaHei"; */
    position: relative;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 0.52rem;
    padding: 10px 20px;
    border-left: 1px solid rgba(12, 12, 12, 0.1);
}
.header-left{
    display: flex;
    align-items: center;
}
.header-right{
     display: flex;
}
.header-name{
    font-size: 0.2rem;
    font-weight:600;
    margin-right: 20px;
}
.weather{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
}
.address{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
}
.time{
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  padding:0 0.1rem;
}
img{
  width: 0.25rem;
  margin-right: 10px;
}
.icon{
  margin: 0px 10px 0px 20px;
}
.main{
    width: 100%;
    height: calc(100% - 0.01rem);
    overflow:hidden;
}
.main2{
    width: 100%;
    height: calc(100% - 0.5rem);
    padding: 0 0.02rem;
    overflow: auto;
}

.main3{
    width: 100%;
    height:calc(100% - 0.3rem);
    margin-bottom: 0.3rem;
    background: #ffffff;
    overflow: hidden;
}
.module{
    width: 100%;
    height: 100%;
}
.kanban{
    height: calc(100% - 1.12rem);
    padding: 0.1rem;
}
.client-floor{
    right: 0.12rem;
    bottom: 0.1rem;
    width: 0.26rem;
    height: 0.26rem;
    border-radius: 0.18rem;
    background-color: rgba(12, 12, 12, 0.1);
    position: absolute;
    cursor: pointer;
}
.client-floor:hover{
    background-color:#7682CE;
}
.floor{
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
}
.floor-left{
    display: flex;
    align-items: center;
    height: 0.6rem;
    background-color: #ffffff;
    padding: 0px  0.2rem;
    border-left: 1px solid rgba(100, 100, 100, 0.1);
}
.floor-right{
    padding: 0px  0.15rem;
    display: flex;
    align-items: center;
    height: 0.6rem;
}
.floor img{
    width: 0.35rem;
    
}
.img-container{
    display: flex;
    align-items: center;
    font-weight:600;
    margin-right: 0.2rem;
}
.interval{
    margin-right: 0.5rem;
}

.card{
    width:48%;
    height: 400px;
    float: left;
    background-color: #ffffff;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}
pre{
    width:100%;
    height: 100%;
   overflow: auto;
}
.tds{
    padding: 0.05rem;
    /* border-left: 1px solid #f5f5f5; */
}
.icon{
    margin: 0 0 0 10px;
}
.pointer{
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border-radius: 5px;
    /* background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent); */
}
::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
    background-color: #F5F5F5;
    border-radius: 5px;
}

.title{
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    padding: 0.15rem;
    border-bottom: 1px solid #e8e8e8;
}
</style>